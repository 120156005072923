import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { LINKS } from '../../components/App/LINKS'
import ButtonGov from '../../components/Btns/ButtonGov'
import LoginForm from '../../components/FormsSmall/LoginForm'
import Loading from '../../components/Loading/Loading'
import { useSystemMaintenance } from '../../hooks/useSystemMaintenance'
import { selectUserIsLoged, selectUserLoading } from '../../redux/user/selectors'

const LoginPage = () => {
  const location = useLocation() as any

  const origin = location.state?.from?.pathname || LINKS.home
  // navigate(origin);
  // navigate(origin, { replace: true });

  const userIsAuthenticated = useSelector(selectUserIsLoged)
  const userLoading = useSelector(selectUserLoading)

  const { maintenanceText, isMaintenance } = useSystemMaintenance()
  const [loginAsAdmin, setLoginAsAdmin] = useState(false)

  if (userLoading) {
    return <Loading />
  }

  if (userIsAuthenticated) {
    return <Navigate to={origin} />
  }

  return (
    <>
      {maintenanceText && (
        <Alert className='text-center m-4 mb-0' variant='warning'>
          {maintenanceText}
        </Alert>
      )}
      {isMaintenance && !loginAsAdmin ? (
        <div className='text-center m-4'>
          <ButtonGov variant='primary' onClick={() => setLoginAsAdmin(true)}>
            Přihlásit jako admin
          </ButtonGov>
        </div>
      ) : (
        <LoginForm />
      )}
    </>
  )
}

export default LoginPage
