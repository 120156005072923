export const IAM_ROLES = {
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  // SUPPORT: 'ROLE_SUPPORT',
  ORGANIZATION_USER: 'ROLE_ORGANIZATION_USER',
  ORGANIZATION_ADMIN: 'ROLE_ORGANIZATION_ADMIN',
  // LOG_VIEWER: 'ROLE_LOG_VIEWER';
}

export const IDSK_ROLES = {
  ADMIN: 'ROLE_ADMIN',
  REFERENT: 'ROLE_REFERENT',
  IDSK_PODEPISUJICI: 'ROLE_IDSK_PODEPISUJICI',
  IDSK_NAHLIZEJICI: 'ROLE_IDSK_NAHLIZEJICI',
  SUBJEKT_PODEPISUJICI: 'ROLE_SUBJEKT_PODEPISUJICI',
  SUBJEKT_KLIENT: 'ROLE_SUBJEKT_KLIENT',
  SUBJEKT_NAHLIZEJICI: 'ROLE_SUBJEKT_NAHLIZEJICI',
}

export const IDSK_ROLES_LABEL = {
  [IDSK_ROLES.ADMIN]: 'Administrátor',
  [IDSK_ROLES.REFERENT]: 'Referent',
  [IDSK_ROLES.IDSK_PODEPISUJICI]: 'Podepisující (IDSK)',
  [IDSK_ROLES.IDSK_NAHLIZEJICI]: 'Nahlížející (IDSK)',
  [IDSK_ROLES.SUBJEKT_PODEPISUJICI]: 'Podepisující',
  [IDSK_ROLES.SUBJEKT_KLIENT]: 'Klient',
  [IDSK_ROLES.SUBJEKT_NAHLIZEJICI]: 'Nahlížející',
}

export const mapIDSKtoIAMrole = {
  [IDSK_ROLES.ADMIN]: IAM_ROLES.SUPER_ADMIN,
  [IDSK_ROLES.REFERENT]: IAM_ROLES.ADMIN,
  [IDSK_ROLES.IDSK_PODEPISUJICI]: IAM_ROLES.ORGANIZATION_ADMIN,
  [IDSK_ROLES.IDSK_NAHLIZEJICI]: IAM_ROLES.ORGANIZATION_USER,
  [IDSK_ROLES.SUBJEKT_PODEPISUJICI]: IAM_ROLES.ORGANIZATION_ADMIN,
  [IDSK_ROLES.SUBJEKT_KLIENT]: IAM_ROLES.ORGANIZATION_USER,
  [IDSK_ROLES.SUBJEKT_NAHLIZEJICI]: IAM_ROLES.ORGANIZATION_USER,
}
