export const IDSK_SUBJEKT_TYPES = {
  OBEC: 'TYPE_OBEC',
  DOPRAVCE: 'TYPE_DOPRAVCE',
  ZELEZNICE: 'TYPE_ZELEZNICE',
  KRAJ: 'TYPE_KRAJ',

  IDSK: 'TYPE_IDSK',
  ROPID: 'TYPE_ROPID',
  DSO: 'TYPE_DSO',

  OSTATNI: 'TYPE_OSTATNI',
}
