import { useFormContext } from 'react-hook-form'
import { CONTRACT_TYPES } from '../enums/enumContractTypes'
import { POCET_STRAN_3, VARIANTA_DODATEK, VARIANTA_DRIVE_ZALOZENA } from '../forms/FormContract/FormContract'

export const useFormDataTypes = () => {
  const { watch } = useFormContext()

  const watchType = watch('typ')
  const contractType = watchType
  const watchPocetStran = watch('pocetStran')
  const watchVariant = watch('varianta')

  return {
    contractType: contractType,
    isP01: contractType === CONTRACT_TYPES.P01,
    isP02: contractType === CONTRACT_TYPES.P02,
    isP03: contractType === CONTRACT_TYPES.P03,
    isP04: contractType === CONTRACT_TYPES.P04,
    isP05: contractType === CONTRACT_TYPES.P05,
    isP06: contractType === CONTRACT_TYPES.P06,
    hasPartC: watchPocetStran === POCET_STRAN_3,
    isDodatek: watchVariant === VARIANTA_DODATEK,
    isDriveZalozena: watchVariant === VARIANTA_DRIVE_ZALOZENA,
  }
}