import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import ButtonGov from '../../components/Btns/ButtonGov'
import ModelSimpleAres from '../../utils/ModelSimpleAres'

const AresLoaderBlock = () => {
  const methods = useFormContext()
  const ico = methods.watch('basicInfo.ic')

  const [loading, setLoading] = useState(false)

  const clickAres = () => {
    if (ico.length !== 8) {
      toast.error('IČ musí mít 8 číslic')
      return
    }
    setLoading(true)

    ModelSimpleAres.aresDataByIco(ico)
      .then((aresData) => {
        methods.setValue('basicInfo.name', aresData.name)
        methods.trigger('basicInfo.name')

        methods.setValue('address', {
          municipality: aresData.town,
          street: aresData.street,
          landRegistryNumber: aresData.numDom,
          houseNumber: aresData.numOrient,
          psc: aresData.postalCode,
          stateCode: 'cz',
        })
        methods.trigger('address')

        toast.success('Název a adresa subjektu načtena z ARES')
      })
      .catch((err) => {
        console.error('ARES chyba', err)
        const code = err.response.status
        if (code === 400) {
          toast.error('Chybné IČO.')
        } else if (code === 404) {
          toast.error('Nenalezen subjekt se zadaným IČO.')
        } else {
          toast.error('Nepodařilo se načíst data z ARES')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <ButtonGov disabled={!ico || loading} variant='primary' onClick={clickAres} loading={loading}>
      Vyhledat v ARES
    </ButtonGov>
  )
}

export default AresLoaderBlock
