import { useCallback, useEffect, useState } from 'react'
import IamApi from '../api/IamApi'
import { useFilterContext } from '../context/FilterContext'
import { iUserData } from '../interfaces/iUserData'
import { createFilterParams, createOrderParamsIam } from '../utils/createParams'

export const useFetchUsers = () => {
  const [data, setData] = useState<iUserData[]>()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const { sort, page, limit, activeFilters } = useFilterContext()

  const fetchData = useCallback(() => {
    setError('')
    setLoading(true)

    const filterParams = createFilterParams(activeFilters)
    const filterOrderParams = createOrderParamsIam(sort.field, sort.order)
    const params = { ...filterParams, ...filterOrderParams }

    IamApi.getUsers(page, limit, params)
      .then((result) => {
        setData(result.data)
        setTotalCount(result.totalCount)
      })
      .catch((error) => {
        setError(error.toString())
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, limit, activeFilters, sort])

  // useEffectStart(() => {
  //   fetchData()
  // })
  // useEffect(() => {
  //   fetchData()
  // }, [page, limit])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { data, loading, error, totalCount, fetchData }
}
