import React from 'react'
import VariantsTabs from '../../components/VariantsTabs/VariantsTabs'
import { VARIANTS_TABLE_LOGS_OPTIONS } from '../../enums/enumTablesVariants'

const ToolbarLogs = () => {
  return (
    <>
      <VariantsTabs variants={VARIANTS_TABLE_LOGS_OPTIONS} />
    </>
  )
}

export default ToolbarLogs
