import React from 'react'
import ResetPasswordForm from '../../components/FormsSmall/ResetPasswordForm'

const UzivatelAktivacePage = () => {
  return (
    <>
      <ResetPasswordForm activate={true} />
    </>
  )
}

export default UzivatelAktivacePage
