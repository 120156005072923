import React from 'react'
import { LINKS } from '../../components/App/LINKS'
import ButtonGovLink from '../../components/Btns/ButtonGovLink'
import PageWrap from '../../components/PageWrap/PageWrap'
import { useUserAuth } from '../../hooks/useUserAuth'

const SpravaPage = () => {
  const { isReferent, isAdmin } = useUserAuth()

  return (
    <>
      <PageWrap title='Správa'>
        <ButtonGovLink variant='primary' to={LINKS.uzivatele} className='me-4'>
          Správa uživatelů
        </ButtonGovLink>

        {isReferent && (
          <ButtonGovLink variant='primary' to={LINKS.spravaLogy} className='me-4'>
            Logy
          </ButtonGovLink>
        )}
        {isAdmin && (
          <ButtonGovLink variant='primary' to={LINKS.spravaSystem} className='me-4'>
            Systém
          </ButtonGovLink>
        )}
      </PageWrap>
    </>
  )
}

export default SpravaPage
