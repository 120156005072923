import React from 'react'
import PageWrap from '../../components/PageWrap/PageWrap'
import { FilterContextProvider } from '../../context/FilterContext'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import TableLogs from '../../tables/TableLogs/TableLogs'
import ToolbarLogs from '../../tables/TableLogs/ToolbarLogs'

const LogyPage = () => {
  return (
    <>
      <PageWrap title='Logy'>
        <FilterContextProvider name={TABLE_TYPES.logs}>
          <ToolbarLogs />
          <TableLogs />
        </FilterContextProvider>
      </PageWrap>
    </>
  )
}

export default LogyPage
