import { iFilter } from '../context/FilterContext'
import { VARIANTS_TABLE_LOGS } from '../enums/enumTablesVariants'
import { iFilterParam } from '../interfaces/typesEformsApi'

const ASC = 'ASC'
const DESC = 'DESC'

export const createFilterParams = (filtersActive: iFilter) => {
  const filters: iFilterParam = {}

  for (const filterName in filtersActive) {
    const value = filtersActive[filterName]
    if (value !== '') {
      filters[filterName] = value
    }
  }
  return filters
}

export const createOrderParams = (sort: string, sortOrder: boolean) => {
  if (sort) {
    return { order: { [sort]: sortOrder ? ASC : DESC } }
  }

  return {}
}

export const createOrderParamsIam = (sort: string, sortOrder: boolean) => {
  if (sort) {
    return { ['order[' + sort + ']']: sortOrder ? ASC : DESC }
  }

  return {}
}

export const createLogsVariantParams = (variant: string) => {
  switch (variant) {
    case VARIANTS_TABLE_LOGS.successLogin:
      return {
        type: 'SECURITY',
        'logData.securityType': 'LOGIN',
        'logData.securityResult': 'SUCCESS',
      }
    case VARIANTS_TABLE_LOGS.failedLogin:
      return {
        type: 'SECURITY',
        'logData.securityType': 'LOGIN',
        'logData.securityResult': 'FAILURE',
      }
    case VARIANTS_TABLE_LOGS.logout:
      return {
        type: 'SECURITY',
        'logData.securityType': 'LOGOUT',
      }
    case VARIANTS_TABLE_LOGS.entityDeletion:
      return {
        type: 'ENTITY_AUDIT',
        'logData.auditAction': 'DELETE',
      }
    default:
      return {}
  }
}