import React from 'react'
import ResetPasswordForm from '../../components/FormsSmall/ResetPasswordForm'

const UzivatelResetHeslaPage = () => {
  return (
    <>
      <ResetPasswordForm />
    </>
  )
}

export default UzivatelResetHeslaPage
