import React from 'react'
import Error from '../../components/Error/Error'
import Loading from '../../components/Loading/Loading'
import NoData from '../../components/NoData/NoData'
import PaginatorPerPage from '../../components/PaginatorPerPage/PaginatorPerPage'
import { useFilterContext } from '../../context/FilterContext'
import { VARIANTS_TABLE_LOGS } from '../../enums/enumTablesVariants'
import { useFetchLogs } from '../../hooks/useFetchLogs'
import { dateFormatLogs } from '../../utils/dateFormat'
import SortTableHeader from '../sort/SortTableHeader'

const TableLogs = () => {
  const { data, loading, error, totalCount } = useFetchLogs()
  const { variant } = useFilterContext()
  const isDeletion = variant === VARIANTS_TABLE_LOGS.entityDeletion

  if (error) {
    return <Error msg={error} />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='gov-table-cover'>
        <table className='gov-table gov-table--tablet-block'>
          <thead>
            <tr>
              <th className='gov-table__cell--wide'>Uživatel</th>
              <th>IP ADRESA UŽIVATELE</th>
              <th>
                <SortTableHeader name='createdAt'>VYTVOŘENO</SortTableHeader>
              </th>
            </tr>
          </thead>
          <tbody className='gov-table__body'>
            {data?.map((log, index) => (
              <tr key={index}>
                <td>{log.userUsername}</td>
                <td>{log.userIpAddress}</td>
                <td>{dateFormatLogs(log.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {!data?.length && <NoData />}

      <PaginatorPerPage totalCount={totalCount} />
    </>
  )
}

export default TableLogs
