import React from 'react'
import InputGovDescription from '../../components/InputsUtils/InputGovDescription'
import InputGovLabel from '../../components/InputsUtils/InputGovLabel'

interface iCheckFilterInput {
  name: string
  label: string
  description?: string
  type?: string
  placeholder?: string
  value: string
  setValue: (value: string) => void
  disabled?: boolean
}

const TextAloneInput = ({
  name,
  label,
  type = 'text',
  description,
  placeholder,
  value,
  setValue,
  disabled
}: iCheckFilterInput) => {
  return (
    <div className={'gov-form-control ' + (value ? 'not-empty' : '')}>
      <input
        id={'input-' + name}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        name={name}
        type={type}
        className='gov-form-control__input'
        aria-required='false'
        aria-disabled='false'
        placeholder={placeholder}
        disabled={disabled}
      />
      <InputGovLabel inputId={'input-' + name} label={label} />
      <InputGovDescription text={description} />
    </div>
  )
}

export default TextAloneInput
