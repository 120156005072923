export const WORKFLOW_PLACES = {
  KONCEPT: 'KONCEPT',
  K_PREPRACOVANI: 'K_PREPRACOVANI',
  STORNO: 'STORNO',

  KE_SCHVALENI_B: 'KE_SCHVALENI_B',
  KE_SCHVALENI_KRAJ: 'KE_SCHVALENI_KRAJ',
  PRIPRAVA_K_PODPISU: 'PRIPRAVA_K_PODPISU',

  PRIPRAVA_DAVKY: 'PRIPRAVA_DAVKY',
  KE_SCHVALENI_KRAJ_DAVKA: 'KE_SCHVALENI_KRAJ_DAVKA',
  PRIPRAVA_K_PODPISU_DAVKA: 'PRIPRAVA_K_PODPISU_DAVKA',

  K_PODPISU_B: 'K_PODPISU_B',
  K_VALIDACI_PODPISU_B: 'K_VALIDACI_PODPISU_B',
  NEVALIDNI_PODPIS_B: 'NEVALIDNI_PODPIS_B',

  KE_KONTROLE_GINIS: 'KE_KONTROLE_GINIS',
  KE_KONTROLE_ROPID: 'KE_KONTROLE_ROPID',

  K_PODPISU_ROPID: 'K_PODPISU_ROPID',
  K_VALIDACI_PODPISU_ROPID: 'K_VALIDACI_PODPISU_ROPID',
  NEVALIDNI_PODPIS_ROPID: 'NEVALIDNI_PODPIS_ROPID',

  K_PODPISU_A: 'K_PODPISU_A',
  K_VALIDACI_PODPISU_A: 'K_VALIDACI_PODPISU_A',
  NEVALIDNI_PODPIS_A: 'NEVALIDNI_PODPIS_A',

  KONEC_PODEPISOVANI_SMLOUVY: 'KONEC_PODEPISOVANI_SMLOUVY',
  KONTROLA_PRED_VYRIZENIM: 'KONTROLA_PRED_VYRIZENIM',
  VYRIZENO: 'VYRIZENO',
}

export const WORKFLOW_PLACES_BATCH = {
  PRIPRAVA: 'PRIPRAVA',
  ODESLANO: 'ODESLANO',
  ZPRACOVANI: 'ZPRACOVANI',
  VYRIZENO: 'VYRIZENO',
}

export const workwlowPlaceOptions = Object.keys(WORKFLOW_PLACES).map((code) => ({ label: code, value: code }))
export const workwlowPlaceBatchOptions = Object.keys(WORKFLOW_PLACES_BATCH).map((code) => ({
  label: code,
  value: code,
}))

export const SIGN_TRANSITIONS = {
  [WORKFLOW_PLACES.K_PODPISU_A]: WORKFLOW_PLACES.K_VALIDACI_PODPISU_A,
  [WORKFLOW_PLACES.K_PODPISU_B]: WORKFLOW_PLACES.K_VALIDACI_PODPISU_B,
  [WORKFLOW_PLACES.K_PODPISU_ROPID]: WORKFLOW_PLACES.K_VALIDACI_PODPISU_ROPID,
}

export const WORFLOW_LABELS = {
  [WORKFLOW_PLACES.KONCEPT]: 'Koncept',
  [WORKFLOW_PLACES.KE_SCHVALENI_B]: 'Ke schválení na straně B',
  [WORKFLOW_PLACES.PRIPRAVA_DAVKY]: 'Příprava dávky',
  [WORKFLOW_PLACES.KE_SCHVALENI_KRAJ_DAVKA]: 'Ke schválení dávky na kraji',
  [WORKFLOW_PLACES.PRIPRAVA_K_PODPISU_DAVKA]: 'Příprava k podpisu v dávce',
  [WORKFLOW_PLACES.K_PODPISU_B]: 'K podpisu na straně B',
  [WORKFLOW_PLACES.KE_KONTROLE_GINIS]: 'Ke kontrole GINIS',
  [WORKFLOW_PLACES.K_PODPISU_ROPID]: 'K podpisu na ROPIDu',
  [WORKFLOW_PLACES.KE_KONTROLE_ROPID]: 'Ke kontrole na ROPIDu',
  [WORKFLOW_PLACES.K_PODPISU_A]: 'K podpisu na straně A',
  [WORKFLOW_PLACES.VYRIZENO]: 'Vyřízeno',
  [WORKFLOW_PLACES.K_PREPRACOVANI]: 'K přepracování',
  [WORKFLOW_PLACES.STORNO]: 'Storno',
  [WORKFLOW_PLACES.K_VALIDACI_PODPISU_A]: 'K validaci podpisu na straně A',
  [WORKFLOW_PLACES.K_VALIDACI_PODPISU_B]: 'K validaci podpisu na straně B',
  [WORKFLOW_PLACES.K_VALIDACI_PODPISU_ROPID]: 'K validaci podpisu na ROPIDu',
  [WORKFLOW_PLACES.NEVALIDNI_PODPIS_A]: 'Nevalidní podpis na straně A',
  [WORKFLOW_PLACES.NEVALIDNI_PODPIS_B]: 'Nevalidní podpis na straně B',
  [WORKFLOW_PLACES.NEVALIDNI_PODPIS_ROPID]: 'Nevalidní podpis u ROPIDu',
  [WORKFLOW_PLACES.KONTROLA_PRED_VYRIZENIM]: 'Kontrola před vyřízením',
  [WORKFLOW_PLACES.KE_SCHVALENI_KRAJ]: 'Ke schválení na kraji',
  [WORKFLOW_PLACES.PRIPRAVA_K_PODPISU]: 'Příprava k podpisu',
  [WORKFLOW_PLACES.KONEC_PODEPISOVANI_SMLOUVY]: 'Konec podepisování smlouvy',
}

export const WORKFLOW_LABELS_BATCH = {
  [WORKFLOW_PLACES_BATCH.PRIPRAVA]: 'Příprava dávky',
  [WORKFLOW_PLACES_BATCH.ODESLANO]: 'Dávka odeslána',
  [WORKFLOW_PLACES_BATCH.ZPRACOVANI]: 'Zpracování dávky',
  [WORKFLOW_PLACES_BATCH.VYRIZENO]: 'Dávka vyřízena',
}
