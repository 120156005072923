import React from 'react'
import ForgotPasswordForm from '../../components/FormsSmall/ForgotPasswordForm'

const UzivatelZapomenuteHesloPage = () => {
  return (
    <>
      <ForgotPasswordForm />
    </>
  )
}

export default UzivatelZapomenuteHesloPage
