import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectSystemConfig } from '../redux/user/selectors'

export const useSystemMaintenance = () => {
  const systemConfig = useSelector(selectSystemConfig)

  const isBetwenDates = systemConfig && moment().isBetween(systemConfig.dateFrom, systemConfig.dateTo)
  const msToStart = systemConfig && systemConfig.turn ? -moment().diff(systemConfig.dateFrom, 'milliseconds') : -1

  return {
    loadedSystemConfig: !!systemConfig,
    isMaintenance: systemConfig?.turn && isBetwenDates,
    maintenanceText: systemConfig?.turn && moment().isBefore(systemConfig.dateTo) ? systemConfig.maintenanceText : '',
    msToMaintenance: msToStart,
  }
}