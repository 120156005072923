import React from 'react'
import { useFormContextBase } from '../../context/FormContext'
import { FILE_EXTENSION } from '../../enums/enumFileExts'
import { WORKFLOW_PLACES as WP } from '../../enums/enumWorkflowPlaces'
import WorkflowCheckboxes from './WorkflowCheckboxes'

const COND_DOC = {
  CONTENT_CONTROL: 'Dokument smlouvy ve formátu DOCX prošel obsahovou kontrolou.',
  CONTENT_CONTROL_REP: 'Opravený dokument smlouvy ve formátu DOCX prošel obsahovou kontrolou.',
  CONV: 'Provedena konverze smlouvy z DOCX do PDF/A-2.',
  PDF_A2: 'Dokument smlouvy ve formátu PDF/A-2 prošel formální kontrolou.',
  KRAJ_TEMPLATE: 'Byl proveden průpis údajů z usnesení do dokumentu smlouvy ve formátu DOCX.',
  KRAJ_CHECK: 'Byly zkontrolovány propsané údaje v dokumentu smlouvy ve formátu DOCX.',
}

const SOFT_CONDITIONS_ADMIN = {
  [WP.KONCEPT]: ['Potřebné údaje ke smlouvě byly zaevidovány.', 'Byly připojeny všechny potřebné přílohy.'],
  [WP.PRIPRAVA_K_PODPISU]: [COND_DOC.CONV, COND_DOC.PDF_A2],
  [WP.PRIPRAVA_K_PODPISU_DAVKA]: [COND_DOC.CONV, COND_DOC.PDF_A2],
  [WP.KE_KONTROLE_GINIS]: ['Byl zapsán výsledek finanční kontroly s datem a případným komentářem.'],
  [WP.KONTROLA_PRED_VYRIZENIM]: [
    'Dokument smlouvy ve formátu PDF/A-2 prošel formální kontrolou před finální distribucí smluvním stranám.',
  ],
  [WP.K_PREPRACOVANI]: ['Potřebné údaje ke smlouvě byly upraveny.', 'Byly zkontrolovány všechny potřebné přílohy.'],
  [WP.KE_SCHVALENI_KRAJ]: [
    'Byl odeslán na kraj email s žádostí o schválení.',
    'Přišel email z kraje s usnesením.',
    'Soubor s usnesením byl vložen k přílohám smlouvy.',
    'Údaje z usnesení byly zapsány do formuláře smlouvy.',
  ],
}

const SOFT_CONDITIONS_B = {
  [WP.KE_SCHVALENI_B]: ['Byl přidán soubor s usnesením.', 'Byl zadán upřesňující text do poznámek uživatele.'],
}

const SOFT_CONDITIONS_ROPID = {
  [WP.KE_KONTROLE_ROPID]: ['Do poznámek pro uživatele byl zapsán výsledek kontroly.'],
}

interface iWorkflowConditions {
  place: string
  isP02P04P06: boolean
  setAllChecked: (allChecked: boolean) => void
  isReferent?: boolean
  isKlientRopid?: boolean
  isKlientB?: boolean
  isDriveZalozena?: boolean
}

const WorkflowConditions = ({
  place,
  isP02P04P06,
  setAllChecked,
  isReferent,
  isKlientB,
  isKlientRopid,
  isDriveZalozena,
}: iWorkflowConditions) => {
  let checkboxesActive: string[] = []
  const { fileMainContract } = useFormContextBase()
  const isPdf = !!fileMainContract && [FILE_EXTENSION.PDF].indexOf(fileMainContract.file.extension) !== -1

  if (isReferent) {
    if (!isDriveZalozena) {
      checkboxesActive = SOFT_CONDITIONS_ADMIN[place] || []
      if ([WP.KONCEPT, WP.K_PREPRACOVANI].includes(place)) {
        if (!isPdf) {
          checkboxesActive = [
            ...checkboxesActive,
            place === WP.KONCEPT ? COND_DOC.CONTENT_CONTROL : COND_DOC.CONTENT_CONTROL_REP,
          ]
        }
        if (isP02P04P06) {
          if (!isPdf) {
            checkboxesActive = [...checkboxesActive, COND_DOC.CONV]
          }
          checkboxesActive = [...checkboxesActive, COND_DOC.PDF_A2]
        }
      }
      if (WP.KE_SCHVALENI_KRAJ === place && !isPdf) {
        checkboxesActive = [...checkboxesActive, COND_DOC.KRAJ_TEMPLATE, COND_DOC.KRAJ_CHECK]
      }
    }
  } else if (isKlientB) {
    checkboxesActive = SOFT_CONDITIONS_B[place] || []
  } else if (isKlientRopid) {
    checkboxesActive = SOFT_CONDITIONS_ROPID[place] || []
  }

  return (
    <WorkflowCheckboxes
      key={checkboxesActive.length}
      title='Podmínky pro přechod smlouvy do dalšího stavu'
      checkboxes={checkboxesActive}
      setAllChecked={setAllChecked}
    />
  )
}

export default WorkflowConditions
