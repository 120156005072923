import React from 'react'
import Error from '../../components/Error/Error'
import PageWrap from '../../components/PageWrap/PageWrap'
import FormSystem from '../../forms/FormSystem/FormSystem'
import { useUserAuth } from '../../hooks/useUserAuth'

const SystemPage = () => {
  const { isAdmin } = useUserAuth()

  if (!isAdmin) {
    return <Error msg={'Na tuto stránku nemáte v sytému oprávnění'} />
  }
  return (
    <>
      <PageWrap title='Systém'>
        <FormSystem />
      </PageWrap>
    </>
  )
}

export default SystemPage
