import React from 'react'
import PageWrap from '../../components/PageWrap/PageWrap'

const PlatbyPage = () => {
  return (
    <>
      <PageWrap title='Platby'>TODO</PageWrap>
    </>
  )
}

export default PlatbyPage
