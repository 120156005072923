export const CONFIG_MAINTENANCE_KEY = 'stck_maintenance'

export interface iSysConfig {
  turn: boolean
  dateFrom: string
  dateTo: string
  maintenanceText: string
}

export interface iConfigDataRes {
  code: string
  createdAt: string
  id: string
  updatedAt: string
  value: any
}