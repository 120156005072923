import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useEffectStart } from '../../hooks/useEffectStart'
import { useSystemMaintenance } from '../../hooks/useSystemMaintenance'
import { useUserAuth } from '../../hooks/useUserAuth'
import { useAppDispatch } from '../../redux/store'
import { loadSystemConfig, loginFromStore, logOutAction } from '../../redux/user/actions'
import { selectUserInit, selectUserIsLoged } from '../../redux/user/selectors'
import Loading from '../Loading/Loading'

interface iAppLoader {
  children?: React.ReactNode
}

let time: any

const AppLoader = ({ children }: iAppLoader) => {
  const userInit = useSelector(selectUserInit)
  const usetIsLoged = useSelector(selectUserIsLoged)
  const { isMaintenance, loadedSystemConfig, msToMaintenance } = useSystemMaintenance()
  const { isAdmin } = useUserAuth()

  const dispatch = useAppDispatch()

  useEffectStart(() => {
    dispatch(loginFromStore())
    dispatch(loadSystemConfig())
  })

  useEffect(() => {
    if (usetIsLoged && !isAdmin && isMaintenance) {
      dispatch(logOutAction('Probíhá odstávka systému'))
    }
  }, [isMaintenance, usetIsLoged, isAdmin])

  useEffect(() => {
    clearTimeout(time)
    if (msToMaintenance > 0) {
      time = setTimeout(() => {
        window.location.reload()
      }, msToMaintenance + 2 * 1000)
    }
  }, [msToMaintenance])

  if (!userInit || !loadedSystemConfig) {
    return <Loading />
  }

  return <>{children}</>
}

export default AppLoader