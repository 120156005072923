import React from 'react'
import InputGovLabel from '../../components/InputsUtils/InputGovLabel'

interface iCheckFilterInput {
  name: string
  label: string
  value: boolean
  setValue: (value: boolean) => void
  disabled?:boolean
}

const CheckAloneInput = ({ name, label, value, setValue, disabled }: iCheckFilterInput) => {
  const id = 'check-' + name
  return (
    <div className='gov-form-control gov-form-control--custom'>
      <ul className='gov-form-group'>
        <li className='gov-form-group__item'>
          <input
            id={id}
            className='gov-form-control__checkbox'
            type='checkbox'
            checked={value}
            onChange={(e) => setValue(e.target.checked)}
            name={name}
            aria-required='false'
            aria-disabled='false'
            disabled={disabled}
          />
          <InputGovLabel inputId={id} label={label} />
          <span className='gov-form-control__indicator'></span>
        </li>
      </ul>
    </div>
  )
}

export default CheckAloneInput
