import { useCallback, useEffect, useState } from 'react'
import AlberaApi from '../api/AlberaApi'
import { useFilterContext } from '../context/FilterContext'
import { iLogsData } from '../interfaces/iLogsData'
import { createLogsVariantParams } from '../utils/createParams'

export const useFetchLogs = () => {
  const [data, setData] = useState<iLogsData[]>()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const { sort, page, limit, variant } = useFilterContext()

  const fetchData = useCallback(() => {
    setError('')
    setLoading(true)

    const params: any = createLogsVariantParams(variant)

    if (sort.field) {
      params['order[' + sort.field + ']'] = sort.order ? 'asc' : 'desc'
    }

    AlberaApi.getLogs(page, limit, params)
      .then((result) => {
        setData(result.data)
        setTotalCount(result.totalCount)
      })
      .catch((error) => {
        setError(error.toString())
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, limit, sort, variant])

  // useEffectStart(() => {
  //   fetchData()
  // })
  // useEffect(() => {
  //   fetchData()
  // }, [page, limit])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { data, loading, error, totalCount, fetchData }
}
