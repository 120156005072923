import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { toast } from 'react-toastify'
import StckApi from '../../api/StckApi'
import Error from '../../components/Error/Error'
import Loading from '../../components/Loading/Loading'
import { CONFIG_MAINTENANCE_KEY, iSysConfig } from '../../interfaces/iSysConfig'
import CheckAloneInput from '../inputs_alone/CheckAloneInput'
import DateAloneInput from '../inputs_alone/DateAloneInput'
import TextAloneInput from '../inputs_alone/TextAloneInput'
import SubmitBtn from '../utils/SubmitBtn'

const FormSystem = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [data, setData] = useState<iSysConfig>()
  const [id, setId] = useState('')

  useEffect(() => {
    StckApi.getConfigItem(CONFIG_MAINTENANCE_KEY)
      .then((res) => {
        setData(res.value)
        setId(res.id)
      })
      .catch((err) => {
        if (err.response.data.error.code === 2305) {
          setData({} as iSysConfig)
        } else {
          setError('Chyba načtení konfigurace: ' + err.toString())
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const [submLoading, setSubmLoading] = useState(false)

  const onSubmit = async (event: any) => {
    event.preventDefault()
    setSubmLoading(true)

    if (data?.turn && (!data.dateFrom || !data.dateTo || !data.maintenanceText)) {
      toast.error('Vyplňte všechna pole')
      setSubmLoading(false)
      return
    }

    const saveFce = (data: any) =>
      id ? StckApi.setConfigItem(id, data) : StckApi.createConfigItem(CONFIG_MAINTENANCE_KEY, data)

    saveFce(data)
      .then((data) => {
        toast.success('Nastavení systému uloženo')
      })
      .catch((err) => {
        toast.error('Chyba uložení nastavení systému')
      })
      .finally(() => {
        setSubmLoading(false)
      })
  }

  if (error) {
    return <Error msg={error} />
  }
  if (loading || submLoading) {
    return <Loading />
  }

  const setValue = (values: Partial<iSysConfig>) => {
    setData((data: any) => ({ ...data, ...values }))
  }

  const disabled = !data?.turn
  return (
    <>
      <form onSubmit={onSubmit}>
        <fieldset style={{ paddingBottom: 150 }}>
          <legend>Nastavení systému</legend>

          <CheckAloneInput
            name={'turn'}
            label={'Zapnout interval odstávky'}
            value={data?.turn || false}
            setValue={(value) => setValue({ turn: value })}
          />

          <TextAloneInput
            disabled={disabled}
            name={'maintenanceText'}
            label={'Text upozornění odstávky'}
            value={data?.maintenanceText || ''}
            setValue={(value) => setValue({ maintenanceText: value })}
          />

          <Row>
            <Col lg={4}>
              <DateAloneInput
                disabled={disabled}
                withTime
                name={'dateFrom'}
                label={'Odstávka od'}
                value={data?.dateFrom || ''}
                setValue={(value) => setValue({ dateFrom: value })}
              />
            </Col>
            <Col lg={4}>
              <DateAloneInput
                disabled={disabled}
                withTime
                name={'dateTo'}
                label={'Odstávka do'}
                value={data?.dateTo || ''}
                setValue={(value) => setValue({ dateTo: value })}
              />
            </Col>
          </Row>
        </fieldset>

        <SubmitBtn title={'Uložit'} loading={submLoading} />
      </form>
    </>
  )
}

export default FormSystem
