import axios from 'axios'
import { interceptorRequestAuthToken } from './interceptorRequestAuthToken'
import { interceptorResponseAuthToken } from './interceptorResponseAuthToken'

class AlberaApi {
  api = axios.create({
    baseURL: process.env.REACT_APP_ALBERA_API_URL,
  })

  constructor() {
    interceptorRequestAuthToken(this.api)
    interceptorResponseAuthToken(this.api)
  }

  getLogs = (page: number, limit: number, params?: any) =>
    this.api
      .get(`/application_logs`, {
        params: {
          page: page,
          limit: limit,
          ...params,
        },
      })
      .then((res) => ({
        data: res.data,
        totalCount: parseInt(res.headers['x-total-count']),
      }))
}

export default new AlberaApi()


