import AresGovApi from '../api/AresGovApi'

interface iAresModel {
  name: string
  address: string
  town: string
  postalCode: string
  ico: string
  street: string
  numDom: string
  numOrient: string
}

class ModelSimpleAres {
  aresDataByIco(ico: string): Promise<iAresModel> {
    return AresGovApi.aresByIco(ico).then((data: any) => {
      const values = {
        nazev: data['obchodniJmeno'],
        obec: data['sidlo']?.['nazevObce'],
        castObce: data['sidlo']?.['nazevCastiObce'],
        ulice: data['sidlo']?.['nazevUlice'],
        cisloDomovni: data['sidlo']?.['cisloDomovni']?.toString(),
        cisloOrientacni: [data['sidlo']?.['cisloOrientacni'], data['sidlo']?.['cisloOrientacniPismeno']]
          .filter((a) => a)
          .join(''),
        psc: data['sidlo']?.['psc']?.toString(),
        kodKraje: data['sidlo']?.['kodKraje'],
        kodStatu: data['sidlo']?.['kodStatu'],
      }

      const cislo = [values.cisloDomovni, values.cisloOrientacni].filter((a) => a).join('/')
      const adresa = [values.ulice || values.obec, cislo].filter((a) => a).join(' ')
      let obec = values.obec
      if (values.castObce && values.obec !== values.castObce) {
        obec += ' - ' + values.castObce
      }

      return {
        name: values.nazev,
        address: adresa,
        town: obec,
        postalCode: values.psc,
        ico: ico,
        street: values.ulice,
        numDom: values.cisloDomovni,
        numOrient: values.cisloOrientacni,
      }
    })
  }
}

export default new ModelSimpleAres()
